import { isEmpty } from 'lodash';
import { useInfiniteQuery } from 'react-query';

import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';

import { Asset, AssetListResponse } from '../types';

export const useGetBrandData = ({ enabled = true }: { enabled?: boolean }) => {
  const client = createHttpClient();
  const queryKey = QUERY_KEYS.BRAND_LIBRARY.BRAND_ASSETS_LIST;

  const query = useInfiniteQuery(
    queryKey,
    ({ pageParam = 'firstPage' }) => {
      const pagination =
        pageParam === 'firstPage' ? { limit: 1000 } : { next: pageParam };

      return client<AssetListResponse>({
        url: END_POINTS.BRAND_LIBRARY.LIST_ASSETS,
        params: pagination,
      });
    },
    {
      getNextPageParam: lastPage =>
        lastPage.cursor?.hasNext ? lastPage.cursor?.next : undefined,
      enabled,
    },
  );

  const { data } = query;

  const brandAssets = (data?.pages ?? []).reduce(
    (allAssets: Asset[], asset) => {
      if (!isEmpty(asset.items)) {
        allAssets.push(...asset.items);
      }

      return allAssets;
    },
    [],
  );

  return { brandAssets, ...query };
};
