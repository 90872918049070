import { Cross } from '@assets/icons';
import { Item } from '@pages/home-page/types';
import { loadCustomFonts } from '@pages/home-page/utils';
import { getFileName } from '@utils/file';
import { FC, useEffect } from 'react';

type FontItemPreviewProps = {
  font: Item;
  handleDeleteFont: () => void;
};

const FontItemPreview: FC<FontItemPreviewProps> = ({
  font,
  handleDeleteFont,
}) => {
  const { name } = font;

  useEffect(() => {
    loadCustomFonts([font]);
  }, []);

  return (
    <div className="flex px-8 w-full gap-6 items-center mb-6">
      <div>
        <div className="font-semibold mb-1 text-sm">Font</div>
        <div className="flex items-center justify-between bg-gray-2 text-base px-2.5 py-1.5 rounded w-[238px] h-10">
          <div className="max-w-[200px] truncate">{name}</div>
          <div className="rounded-full bg-grey-11 w-[18px] h-[18px] flex items-center justify-center">
            <Cross
              className="w-4 h-4 cursor-pointer"
              onClick={handleDeleteFont}
            />
          </div>
        </div>
      </div>
      <div className="w-full">
        <div className="font-semibold mb-1 tetx-sm">Preview</div>
        <div style={{ fontFamily: getFileName(name) }} className="text-base">
          <div className="flex items-center justify-between bg-gray-7 h-10 w-full text-sm tracking-tight px-3 py-1.5 rounded-sm">
            The five boxing wizards jump quickly.
          </div>
        </div>
      </div>
    </div>
  );
};

export default FontItemPreview;
