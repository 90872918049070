import { useMutation } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

import { ImportAssetIdsBody } from '../types';

export const useImportAssetIds = () => {
  const client = createHttpClient();

  const mutation = useMutation(
    (payload: ImportAssetIdsBody) =>
      client({
        method: HttpMethod.POST,
        url: END_POINTS.BRAND_LIBRARY.IMPORT_ASSET_IDS,
        data: payload,
      }),
    {
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Something went wrong',
          message: `${error?.message || 'request failed'}`,
        });
      },
      onSuccess: () => {
        showAlert({
          type: AlertType.SUCCESS,
          message:
            'Asset IDs were sent. Please refresh the page in a few minutes to see them.',
        });
      },
    },
  );

  return mutation;
};
