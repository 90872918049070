import { FC } from 'react';

import { Dropzone } from '@components/index';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import { UNSUPPORTED_FILE_EXT_ERROR } from '@pages/home-page/constants';

interface CustomUploaderProps {
  allowableFileExt: string;
  onClick: (files: File[]) => void;
  isDisabled?: boolean;
}

export const CustomUploader: FC<CustomUploaderProps> = ({
  allowableFileExt,
  onClick,
  isDisabled = false,
}) => {
  return (
    <Dropzone
      accept={allowableFileExt}
      onFilesDrop={onClick}
      multiple
      onFilesReject={() =>
        showAlert({
          type: AlertType.ERROR,
          message: `${UNSUPPORTED_FILE_EXT_ERROR}${allowableFileExt}`,
        })
      }
    >
      <div
        className={`flex py-6 justify-center items-center border rounded-lg w-full border-dashed border-gray-4
      ${isDisabled ? 'opacity-50' : ''}
      `}
      >
        <div className="flex gap-2">
          <span className="text-gray-5 pl-2.5">
            Drag and drop files here or{' '}
          </span>
          <button className="underline">Upload</button>
        </div>
      </div>
    </Dropzone>
  );
};
