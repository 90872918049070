import { useRecoilValue } from 'recoil';

import { chatList } from '@store/atoms/chat';

import ChatBox from './ChatBox';
import ChatList from './ChatList';
import ChatSuggestions from './ChatSuggestions';

const ChatPanel = () => {
  const chatListItems = useRecoilValue(chatList);

  return (
    <div className="flex flex-col w-[32%] min-w-[400px] h-screen border-r border-gray-6 pt-4">
      <div className="h-[65px] flex justify-between items-center pl-4 border-b border-gray-6">
        <span className="text-2xl font-semibold">Chat</span>
      </div>
      <div className="h-[calc(100vh-65px)] flex flex-col">
        <ChatList />
        {chatListItems?.length === 1 && <ChatSuggestions />}
        <ChatBox />
      </div>
    </div>
  );
};

export default ChatPanel;
