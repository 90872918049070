import BrandLibrary from './components/brand-library/BrandLibrary';
import ChatPanel from './components/chat-panel/ChatPanel';

const HomePage = () => {
  return (
    <>
      <ChatPanel />
      <BrandLibrary />
    </>
  );
};

export default HomePage;
