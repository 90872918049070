import { each, isEmpty, isNil, isObject, isString, map } from 'lodash';

import { KnowledgeAttribute } from '@store/atoms/knowledge-attributes';

import { AttributeType, DataItem } from './types';

export const doesValueExist = (value: string, searchTerm: string) => {
  return value.toLowerCase().includes(searchTerm.toLowerCase());
};

export const searchObject = (obj: DataItem, searchTerm: string) => {
  for (const value of Object.values(obj)) {
    // Searching in nested objects
    if (isObject(value) && !isNil(value)) {
      if (searchObject(value as DataItem, searchTerm)) {
        return true;
      }
    } else if (isString(value) && doesValueExist(value, searchTerm)) {
      return true;
    }
  }

  return false;
};

export const filterResults = (searchTerm: string, assets: DataItem[]) => {
  if (searchTerm) {
    const filteredResults = assets?.filter((item: DataItem) =>
      searchObject(item, searchTerm),
    );

    return filteredResults;
  }

  return assets;
};

export const getTruncatedFontName = (fontName: string) => {
  return fontName ? fontName.match(/(.+?)(\.[^.]*$|$)/)?.[1] : '';
};

export const loadCustomFonts = async (customFonts: any[]) => {
  // remove extension from font file to create font family
  const customFontFaces = customFonts?.map(font => {
    const fontName = font?.name || font?.fileName || '';

    return {
      fontAsset: font,
      font: new FontFace(
        getTruncatedFontName(fontName) || '',
        `url(${font.thumbnailUrl})`,
      ),
    };
  });

  const loadedFontsPromise = Promise.all(
    map(customFontFaces, cff => {
      return cff.font.load().catch(error => {
        console.warn(`Error loading font: ${cff.font.family}`);

        return { error, font: cff.fontAsset };
      });
    }),
  );

  const loadedFonts = await loadedFontsPromise;
  const fontErrors: any = [];
  if (!isEmpty(loadedFonts)) {
    each(loadedFonts as any[], res => {
      if (res.error) {
        fontErrors.push(res.font);
      } else {
        (document as any).fonts.add(res);
      }
    });
  }
  await document.fonts.ready;

  return fontErrors;
};

export const convertKnowledgeOptionsToArray = (
  knowledgeOptions: KnowledgeAttribute[],
): AttributeType[] => {
  return knowledgeOptions.map(item => ({
    id: item.attributeName,
    label: item.displayName,
  }));
};
