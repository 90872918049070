import { FC } from 'react';

import { CrossFilled } from '@assets/icons';
import { iconMap } from '@pages/home-page/constants';
import { RawFileTypes } from '@pages/home-page/enums';
import { Item } from '@pages/home-page/types';
import { getFileExt } from '@utils/file';

type DocumentUploadItemPreviewProps = {
  item: Item;
  handleDeleteItem: () => void;
};

const DocumentUploadItemPreview: FC<DocumentUploadItemPreviewProps> = ({
  item,
  handleDeleteItem,
}) => {
  const { name } = item;
  const fileExtension = getFileExt(name) as RawFileTypes;
  const FileIcon = iconMap[fileExtension];

  return (
    <div className="flex w-full px-8 gap-6 items-center mb-6">
      <div className="relative group">
        {FileIcon && <FileIcon className="h-10 w-10 " />}
        <CrossFilled
          className="absolute -top-3 -right-3 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity"
          onClick={handleDeleteItem}
        />
      </div>
      <div className="w-full">
        <div className="font-semibold mb-1">{name}</div>
      </div>
    </div>
  );
};

export default DocumentUploadItemPreview;
