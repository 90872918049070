import { ChatResponse } from '@hooks/types';
import { SoketiResponse, usePusher } from '@hooks/use-pusher';
import { ChatRole } from '@pages/home-page/enums';
import {
  chatLanguage,
  chatList,
  chatLoaderState,
  chatSessionId,
  selectedChatSuggestion,
} from '@store/atoms/chat';
import { userProfile } from '@store/atoms/user';
import { workspace } from '@store/atoms/workspace';
import { useEffect } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

export const useChatResponse = ({
  session_id,
  onChatResponse,
}: {
  session_id: string;
  onChatResponse: () => void;
}) => {
  const user = useRecoilValue(userProfile);
  const currentWorkspace = useRecoilValue(workspace);

  const setChatList = useSetRecoilState(chatList);

  const resetChatList = useResetRecoilState(chatList);
  const resetChatLoaderState = useResetRecoilState(chatLoaderState);
  const resetSelectedChatSuggestion = useResetRecoilState(
    selectedChatSuggestion,
  );
  const resetChatLanguage = useResetRecoilState(chatLanguage);
  const resetChatSessionId = useResetRecoilState(chatSessionId);

  const { bindEvent, unbindEvent } = usePusher(currentWorkspace.id);

  useEffect(
    function chatResponseEvent() {
      if (!user.id || !session_id) return;
      const soketiEvent = `CHAT_${user.id}_${session_id}:RESPONSE`;

      bindEvent(soketiEvent, (data: SoketiResponse<ChatResponse>) => {
        const chatResponse = data?.message?.content;

        setChatList(prevChatList => [
          ...prevChatList,
          {
            role: ChatRole.AI,
            data: chatResponse,
          },
        ]);

        onChatResponse();
      });

      return () => {
        unbindEvent(soketiEvent);
        resetChatList();
        resetChatLoaderState();
        resetChatLanguage();
        resetSelectedChatSuggestion();
        resetChatSessionId();
      };
    },
    [session_id, user.id],
  );
};
