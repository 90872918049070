import { useState } from 'react';

import { useUploadFileToR2 } from '@hooks/use-upload-file-to-r2';

import { useCreateBrandAsset } from './use-create-brand-asset';
import { AssetType, RawFileCategory } from '../enums';
import { CreateBrandAssetRequest, Item } from '../types';

export const useBrandLibrary = () => {
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const uploadFileToR2 = useUploadFileToR2();

  const { mutateAsync: createBrandAsset, isLoading: loadingCreateBrandAsset } =
    useCreateBrandAsset();

  const createAssets = async (
    uploadedData: {
      s3Bucket: string;
      s3Key: string;
    },
    fileName: string,
    assetType: AssetType,
  ) => {
    const brandAsset: CreateBrandAssetRequest = {
      assetType: assetType,
      fileName,
      s3Key: uploadedData.s3Key,
      s3Bucket: uploadedData.s3Bucket,
    };
    if (assetType === AssetType.RAW_FILE) {
      brandAsset['metadata'] = {
        type: RawFileCategory.BRAND_BOOK,
      };
    }

    await createBrandAsset(brandAsset);
  };

  const handleUploadFiles = async (
    uploadedFiles: File[],
    assetType: AssetType,
  ) => {
    const fileName = uploadedFiles[0]?.name;
    const response = await uploadFileToR2({
      file: uploadedFiles[0],
      filename: fileName,
    });
    createAssets(response, fileName, assetType);
  };

  const handleSave = async (
    items: Item[],
    handleClose: () => void,
    assetType: AssetType,
  ) => {
    const uploadFiles: File[] = [];
    setIsUploading(true);

    await Promise.all(
      items.map(async item => {
        const response = await fetch(item.thumbnailUrl);
        const blob = await response.blob();
        const file = new File([blob], `${item.name}`, { type: blob.type });
        uploadFiles.push(file);
      }),
    );
    uploadFiles.forEach(file => {
      handleUploadFiles([file], assetType as AssetType);
    });

    setIsUploading(false);

    handleClose();
  };

  return {
    handleUploadFiles,
    handleSave,
    isUploading,
    createBrandAsset,
    loadingCreateBrandAsset,
  };
};
