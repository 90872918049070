import { useEffect } from 'react';

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as uuid_v4 } from 'uuid';

import { ChatRole, Language } from '@pages/home-page/enums';
import {
  chatLanguage,
  chatList,
  chatLoaderState,
  chatSessionId,
  selectedChatSuggestion,
} from '@store/atoms/chat';

import { useChatRequest } from './use-chat-request';
import { useChatResponse } from './use-chat-response';

export const useChatGeneration = () => {
  const chatSuggestion = useRecoilValue(selectedChatSuggestion);
  const selectedLanguage = useRecoilValue(chatLanguage);

  const setChatList = useSetRecoilState(chatList);
  const setIsGeneratingChat = useSetRecoilState(chatLoaderState);

  const [currentChatSessionId, setChatSessionId] =
    useRecoilState(chatSessionId);

  const { mutateAsync: sendChatRequest } = useChatRequest();

  useEffect(() => {
    if (chatSuggestion) onGenerateChat(chatSuggestion, selectedLanguage.id);
  }, [chatSuggestion]);

  const onGenerateChat = (chatInput: string, language: Language) => {
    setIsGeneratingChat(true);

    setChatList(prevChatList => [
      ...prevChatList,
      {
        role: ChatRole.HUMAN,
        data: {
          response: chatInput,
        },
      },
    ]);

    if (currentChatSessionId) {
      sendChatRequest({
        humanMessage: chatInput,
        language,
        session_id: currentChatSessionId,
      });
    } else {
      const newId = uuid_v4();
      sendChatRequest({
        humanMessage: chatInput,
        language,
        session_id: newId,
      });
      setChatSessionId(newId);
    }
  };

  useChatResponse({
    session_id: currentChatSessionId,
    onChatResponse: () => {
      setIsGeneratingChat(false);
    },
  });

  return {
    onGenerateChat,
  };
};
