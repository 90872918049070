import { FC } from 'react';

import { Upload } from '@assets/icons';
import { Dropzone } from '@components/index';
import { FileExt } from '@pages/home-page/enums';

import AssetIdInput from './AssetIdInput';

interface BrandUploaderProps {
  onUploadFiles?: (files: File[]) => void;
  showAssetIdInput?: boolean;
}

const BrandUploader: FC<BrandUploaderProps> = ({
  onUploadFiles,
  showAssetIdInput = true,
}) => {
  return (
    <div className="w-full h-fit py-8 border-dashed border rounded-2xl border-gray-4 flex px-4 items-center">
      {showAssetIdInput && <AssetIdInput />}
      <div className="flex justify-center ml-12">Or</div>
      <div className="flex w-full items-center justify-center gap-6">
        <span className="rounded-full p-2 bg-lighter">
          <Upload className="text-primary" />
        </span>
        <div>
          <div className="flex text-lg">
            <Dropzone
              onFilesDrop={onUploadFiles}
              multiple
              accept={FileExt.BRAND_FILES}
            >
              <span className="cursor-pointer text-darker"> Click here </span>
            </Dropzone>
            &nbsp; to upload a file or drag and drop
          </div>
          <div className="text-base text-gray-5 mb-3">
            Supported file format .ppt, .pptx, PDF and DOCX
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrandUploader;
