import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

import { ASSET_INFO } from '../constants';
import { AssetType } from '../enums';
import {
  AssetListResponse,
  CreateBrandAssetRequest,
  CreateBrandAssetResponse,
} from '../types';

export const useCreateBrandAsset = () => {
  const client = createHttpClient();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (payload: CreateBrandAssetRequest) =>
      client<CreateBrandAssetResponse>({
        method: HttpMethod.POST,
        url: END_POINTS.BRAND_LIBRARY.CREATE_ASSET,
        data: payload,
      }),
    {
      onSuccess: (data: CreateBrandAssetResponse) => {
        showAlert({
          message: `${ASSET_INFO[data.assetType as AssetType]?.name} added successfully`,
          type: AlertType.SUCCESS,
        });

        if (
          data?.assetType === AssetType.KNOWLEDGE ||
          data?.assetType === AssetType?.RAW_FILE
        ) {
        }
        queryClient.refetchQueries(QUERY_KEYS.HIERARCHY.ASSETS_HIERARCHY);

        const assetListCache = queryClient.getQueryData<
          InfiniteData<AssetListResponse>
        >(QUERY_KEYS.BRAND_LIBRARY.BRAND_ASSETS_LIST);

        // Cache updation
        assetListCache?.pages[0].items.push(data);

        queryClient.setQueryData(QUERY_KEYS.BRAND_LIBRARY.BRAND_ASSETS_LIST, {
          ...assetListCache,
        });
      },
    },
  );

  return mutation;
};
