import { atom } from 'recoil';

import { DataItem } from '@pages/home-page/types';

import { Atoms } from '../constants';

export const documentList = atom<DataItem[]>({
  key: Atoms.documentList,
  default: [],
});
