import { AxiosProgressEvent } from 'axios';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';

import { useUploadProgress } from './use-upload-progress';

interface PreSignedUrlResponse {
  preSignedUrl: string;
  s3Bucket: string;
  s3Key: string;
}

export const useUploadFileToR2 = () => {
  const client = createHttpClient();
  const clientWithoutCreds = createHttpClient({ allowWithCredentials: false });

  const { upsert, remove } = useUploadProgress();

  const uploadFileToR2 = async ({
    filename,
    file,
  }: {
    filename: string;
    file: File;
  }) => {
    const { preSignedUrl, s3Bucket, s3Key } =
      await client<PreSignedUrlResponse>({
        url: END_POINTS.BRAND_LIBRARY.GET_PRESIGNED_URL,
        params: { filename },
      });

    await clientWithoutCreds({
      url: preSignedUrl,
      method: HttpMethod.PUT,
      data: file,
      onUploadProgress: (progressEvent: AxiosProgressEvent) => {
        if (progressEvent?.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent?.total,
          );

          if (percentCompleted === 100)
            remove({
              name: filename,
              progressPercentage: percentCompleted,
            });
          else {
            upsert({
              name: filename,
              progressPercentage: percentCompleted,
            });
          }
        }
      },
    });

    return {
      s3Bucket,
      s3Key,
    };
  };

  return uploadFileToR2;
};
