import { atom } from 'recoil';

import { ChatResponse } from '@hooks/types';
import { DEFAULT_LANGUAGE } from '@pages/home-page/constants';
import { ChatRole } from '@pages/home-page/enums';

import { Atoms } from '../constants';

export type ChatListItem = {
  role: ChatRole;
  data: ChatResponse;
};

export const defaultChatItem = [
  {
    role: ChatRole.AI,
    data: {
      response: 'Ask me anything that you would like to know about the Brand.',
      metadata: {},
    },
  },
] as ChatListItem[];

export const chatList = atom({
  key: Atoms.chatList,
  default: defaultChatItem,
});

export const chatLoaderState = atom({
  key: Atoms.chatLoaderState,
  default: false,
});

export const selectedChatSuggestion = atom({
  key: Atoms.selectedChatSuggestion,
  default: '',
});

export const chatLanguage = atom({
  key: Atoms.chatLanguage,
  default: DEFAULT_LANGUAGE,
});

export const chatSessionId = atom({
  key: Atoms.chatSessionId,
  default: '',
});
