import { atom } from 'recoil';

import { Atoms } from '../constants';

export type KnowledgeAttribute = {
  id: number;
  displayName: string;
  attributeName: string;
};

export const knowledgeAttributes = atom<KnowledgeAttribute[]>({
  key: Atoms.knowledgeAttributes,
  default: [],
});
