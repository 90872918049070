import { FC } from 'react';

import { CrossFilled, Video } from '@assets/icons';
import { Input } from '@components/index';
import { AssetType } from '@pages/home-page/enums';
import { Item } from '@pages/home-page/types';

type ItemDetailsPreviewProps = {
  item: Item;
  handleItemNameChange: (key: string | number, name: string) => void;
  handleDeleteItem: () => void;
  isEdit?: boolean;
};

const ItemDetailsPreview: FC<ItemDetailsPreviewProps> = ({
  item,
  handleItemNameChange,
  handleDeleteItem,
  isEdit = false,
}) => {
  const { key, thumbnailUrl, name, assetType } = item;

  return (
    <div className="flex px-8 w-full gap-6 items-center mb-6">
      <div className="relative min-w-[100px] group">
        {assetType === AssetType.VIDEO ? (
          <Video className="w-[100px]" />
        ) : (
          <img
            src={thumbnailUrl}
            alt="Uploaded"
            className="object-fill rounded-md h-[100px] w-[100px]"
          />
        )}

        {!isEdit && (
          <CrossFilled
            className="absolute -top-3 -right-3 cursor-pointer opacity-0 group-hover:opacity-100 transition-opacity"
            onClick={handleDeleteItem}
          />
        )}
      </div>
      <div className="w-full">
        <div className="font-semibold mb-1">Name</div>
        <Input
          customInputClass="w-full h-10"
          value={name}
          onChange={e => handleItemNameChange(key, e.target.value)}
        />
      </div>
    </div>
  );
};

export default ItemDetailsPreview;
