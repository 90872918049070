import { FC, useState } from 'react';

import { AssetBank, Upload } from '@assets/icons';
import { Button } from '@components/index';
import { AssetType } from '@pages/home-page/enums';

import AddEditModalLayout from './AddEditModalLayout';
import AssetIdInput from '../AssetIdInput';
import ItemUploadModal from './ItemUploadModal';

interface AddDocumentModalProps {
  isOpen: boolean;
  onCloseClick: () => void;
}

const AddDocumentModal: FC<AddDocumentModalProps> = ({
  isOpen,
  onCloseClick,
}) => {
  const [isUpload, setIsUpload] = useState(false);

  const renderSeparator = () => (
    <>
      <div className="flex-grow border-t border-gray-2"></div>
      <span className="mx-4 text-gray-500">or</span>
      <div className="flex-grow border-t border-gray-2"></div>
    </>
  );

  return (
    <AddEditModalLayout
      isOpen={isOpen}
      header="Add documents"
      onClose={onCloseClick}
    >
      <div>
        {isUpload ? (
          <>
            <div className="px-8 pb-4">
              <Button
                label="Upload from 'The Asset Bank'"
                IconLeftComponent={AssetBank}
                onClick={() => setIsUpload(false)}
                classOverride="border-gray-4 p-2 font-normal"
              />
            </div>
            <div className="flex items-center my-3 px-8">
              {renderSeparator()}
            </div>
            <ItemUploadModal
              onCloseClick={onCloseClick}
              assetType={AssetType.RAW_FILE}
            />
          </>
        ) : (
          <div className="px-8 pb-8">
            <AssetIdInput showImportButton={true} classOverride="w-full" />
            <div className="flex items-center my-3">{renderSeparator()}</div>
            <div className="mt-4">
              <Button
                label="Upload File"
                IconLeftComponent={Upload}
                onClick={() => setIsUpload(true)}
                classOverride="border-gray-4 p-2 font-normal"
              />
            </div>
            <div />
          </div>
        )}
      </div>
    </AddEditModalLayout>
  );
};
export default AddDocumentModal;
