import { FC, ReactNode } from 'react';

import { Modal } from '@components/index';

interface AddEditModalLayoutProps {
  isOpen: boolean;
  children: ReactNode;
  onClose: () => void;
  classOverride?: string;
  header?: string;
}

const AddEditModalLayout: FC<AddEditModalLayoutProps> = ({
  isOpen,
  classOverride = '',
  onClose,
  children,
  header = '',
}) => {
  return (
    <Modal isOpen={isOpen} classOverride={classOverride} onClose={onClose}>
      <div className="max-h-[700px] pt-8 w-[600px] rounded-2xl overflow-hidden">
        <div className="h-full">
          <div className="px-8 mb-6">
            {header && <div className="text-base font-medium">{header}</div>}
          </div>
          {children}
        </div>
      </div>
    </Modal>
  );
};

export default AddEditModalLayout;
