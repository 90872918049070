import { useEffect } from 'react';

import isNil from 'lodash/isNil';
import { useQueryClient } from 'react-query';

import { useRecoilValue } from 'recoil';
import { workspace } from '@store/atoms/workspace';
import { SoketiResponse, usePusher } from '@hooks/use-pusher';
import { QUERY_KEYS } from '@constants/query-keys';
import { ExtractionResponse } from '../types';
import { KnowledgeExtractionStatus } from '../enums';

type ExtractBrandKnowledgeParams = {
  onExtractionComplete: (
    status:
      | KnowledgeExtractionStatus.SUCCESS
      | KnowledgeExtractionStatus.FAILURE,
    message?: string,
  ) => void;
};

export const useExtractBrandKnowledge = ({
  onExtractionComplete,
}: ExtractBrandKnowledgeParams) => {
  const queryClient = useQueryClient();
  const currentWorkspace = useRecoilValue(workspace);
  const workspaceId = currentWorkspace?.id;

  const { pusherChannel, bindEvent, unbindEvent } = usePusher(workspaceId);

  const handleSoketiResponse = (data: SoketiResponse<ExtractionResponse>) => {
    const brandKnowledgeResponse = data;
    const { content, error } = brandKnowledgeResponse.message;
    const chatMessage = content?.chat_message;
    const status = chatMessage?.status;
    const errorMessage = chatMessage?.error_message;

    if (status === KnowledgeExtractionStatus.FAILURE || error) {
      onExtractionComplete(KnowledgeExtractionStatus.FAILURE, errorMessage);

      return;
    }

    if (status === KnowledgeExtractionStatus.SUCCESS) {
      queryClient.refetchQueries(QUERY_KEYS.BRAND_LIBRARY.BRAND_ASSETS_LIST);
      queryClient.refetchQueries(QUERY_KEYS.HIERARCHY.ASSETS_HIERARCHY);

      return onExtractionComplete(KnowledgeExtractionStatus.SUCCESS);
    }
  };

  useEffect(
    function brandKnowledgeEvent() {
      if (isNil(workspaceId) || isNil(pusherChannel)) return;

      bindEvent(
        `KNOWLEDGE_EXTRACTION:PROGRESS:${workspaceId}`,
        handleSoketiResponse,
      );

      return () => {
        unbindEvent(`KNOWLEDGE_EXTRACTION:PROGRESS:${workspaceId}`);
      };
    },
    [workspaceId, pusherChannel],
  );
};
