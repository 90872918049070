import { isEmpty } from 'lodash';
import { InfiniteData, useMutation, useQueryClient } from 'react-query';

import { HttpMethod } from '@api/enums';
import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';

import { AssetEditData, AssetListResponse } from '../types';

export const useUpdateBrandAsset = () => {
  const client = createHttpClient();
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ assetId, metadata, fileName }: AssetEditData) => {
      return client({
        method: HttpMethod.PATCH,
        url: END_POINTS.BRAND_LIBRARY.EDIT_ASSET(assetId),
        data: {
          ...(metadata && { metadata }),
          ...(fileName && { fileName: fileName }),
        },
      });
    },
    {
      onSuccess: (_, variables) => {
        const cache = queryClient.getQueryData<InfiniteData<AssetListResponse>>(
          QUERY_KEYS.BRAND_LIBRARY.BRAND_ASSETS_LIST,
        );

        if (isEmpty(cache?.pages)) {
          return;
        }

        const updatedPages = cache?.pages?.map(page => {
          const updatedItems = page.items.map(item => {
            if (item.id === variables.assetId) {
              return {
                ...item,
                ...(variables.metadata && {
                  metadata: variables.metadata,
                }),
                ...(variables.fileName && {
                  name: variables.fileName,
                }),
              };
            }

            return item;
          });

          return { ...page, items: updatedItems };
        });

        queryClient.setQueryData<InfiniteData<AssetListResponse>>(
          QUERY_KEYS.BRAND_LIBRARY.BRAND_ASSETS_LIST,
          {
            ...cache,
            pages: updatedPages,
          },
        );

        showAlert({
          type: AlertType.SUCCESS,
          message: 'Asset updated succesfully',
        });
      },

      onError: () => {
        showAlert({
          type: AlertType.ERROR,
          message: 'Failed to update asset',
        });
      },
    },
  );

  return mutation;
};
