import { FC } from 'react';

import { ASSET_INFO } from '@pages/home-page/constants';
import { AssetType } from '@pages/home-page/enums';
import { DataItem } from '@pages/home-page/types';

import AddEditModalLayout from './AddEditModalLayout';
import ItemUploadModal from './ItemUploadModal';

interface ItemUploadModalWrapperProps {
  isOpen: boolean;
  isEdit?: boolean;
  assetType: AssetType;
  onCloseClick: () => void;
  defaultValues?: DataItem;
}

const ItemUploadModalWrapper: FC<ItemUploadModalWrapperProps> = ({
  isOpen,
  isEdit = false,
  assetType,
  onCloseClick,
  defaultValues,
}) => {
  const assetName = ASSET_INFO[assetType].name.toLowerCase();

  return (
    <AddEditModalLayout
      isOpen={isOpen}
      header={`${isEdit ? 'Edit' : 'Add'} ${assetName}`}
      onClose={onCloseClick}
    >
      <ItemUploadModal
        defaultValues={defaultValues}
        isEdit={isEdit}
        assetType={assetType}
        onCloseClick={onCloseClick}
      />
    </AddEditModalLayout>
  );
};

export default ItemUploadModalWrapper;
