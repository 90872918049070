export const getFileExt = (name: string) => {
  return name?.split('.').slice(-1)[0];
};

export const getFileName = (name: string) => {
  return name.includes('.') ? name.split('.').slice(0, -1).join('.') : name;
};

export const getSanitizedFileName = (name: string) => {
  return name.replace(/[^\x00-\x7F]/g, '_');
};
