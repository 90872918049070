import { useQuery } from 'react-query';
import { useSetRecoilState } from 'recoil';

import { createHttpClient } from '@api/http-client';
import { END_POINTS } from '@constants/api-endpoints';
import { QUERY_KEYS } from '@constants/query-keys';
import { AlertType } from '@enums/alert';
import { showAlert } from '@lib/alert/alert-manager';
import { knowledgeAttributes } from '@store/atoms/knowledge-attributes';

export type KnowlegeAttributeItem = {
  id: number;
  client_id: number;
  name: string;
  metadata: {
    prompt: string;
    attribute_name: string;
  };
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
};

export const useGetKnowledgeAttribute = () => {
  const client = createHttpClient();

  const setKnowledgeAttributes = useSetRecoilState(knowledgeAttributes);

  const query = useQuery(
    QUERY_KEYS.KNOWLEDGE_ATTRIBUTE.KNOWLEDGE_ATTRIBUTE_LIST,
    () =>
      client<KnowlegeAttributeItem[]>({
        url: END_POINTS.KNOWLEDGE_ATTRIBUTE.GET_KNOWLEDGE_ATTRIBUTE,
      }),
    {
      onSuccess: data => {
        const formattedData = data.map(item => ({
          id: item.id,
          displayName: item.name,
          attributeName: item.metadata.attribute_name,
        }));

        setKnowledgeAttributes(formattedData);
      },
      onError: (error: Error) => {
        showAlert({
          type: AlertType.ERROR,
          header: 'Failed to fetch knowledge attributes',
          message: `${error?.message || 'Request failed'}`,
        });
      },
    },
  );

  return query;
};
